<template>

  <div class="location-info">

    <div class="location-fiche">


      <div class="right">
        <headline bg>
          {{ $t("location.additional-information") }}
        </headline>

        <div class="grid">

<!--   <Map ref="map" :items="markers" :isLocation="true"></Map> -->


          <div class="container">
            <div class="row">
              <div class="col-sm">
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('name') }} :
                  </v-col>
                  <v-col sm="9">
                    <!-- {{ customer.type ? $t(customer.type) : '-' }}-->
                    {{location.name}}

                    <v-btn small class="ml-3" :to="{name: 'location.edit'}">
                      {{ $t('charging-point.edit-location') }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('organization.organization') }} :
                  </v-col>
                  <v-col sm="9">
                    <router-link :to="{name: 'organizations.detail', params: {id: location.organization.id }}" v-if="location.organization">
                      {{ location.organization.name}}
                    </router-link>
                    <template v-else>-</template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('address') }} :
                  </v-col>
                  <v-col sm="9" class="address">
                    <template v-if="location.address && location.address.street">
                      {{ location.address.number }} {{ location.address.street }}
                    </template>
                    <template v-else>-</template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('additional-information') }} :
                  </v-col>
                  <v-col sm="9">
                    <template v-if="location.address && location.address.additionalInformation">
                      {{ location.address.additionalInformation }}
                    </template>
                    <template v-else>-</template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label">
                    {{ $t('zipcode') }} :
                  </v-col>
                  <v-col>
                    <template v-if="location.address && location.address.zipcode">
                      {{ location.address.zipcode }}
                    </template>
                    <template v-else>-</template>
                  </v-col>
                  <v-col class="label">
                    {{ $t('city') }} :
                  </v-col>
                  <v-col>
                    <template v-if="location.address && location.address.city">
                      {{ location.address.city }}
                    </template>
                    <template v-else>-</template>
                  </v-col>
                  <v-col class="label">
                    {{ $t('state') }} :
                  </v-col>
                  <v-col>
                    <template v-if="location.address && location.address.state">
                      {{ location.address.state }}
                    </template>
                    <template v-else>-</template>
                  </v-col>
                  <v-col class="label">
                    {{ $t('country') }} :
                  </v-col>
                  <v-col>
                    <template v-if="location.address && location.address.country">
                      {{ location.address.country }}
                    </template>
                    <template v-else>-</template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('publish') }} :
                  </v-col>

                  <v-col sm="3">
                    <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                      <v-switch
                          color="#00897E"
                          inset
                          hide-details
                          readonly
                          v-model="location.publish"

                      />
                      <span>
                  {{ $t('yes') }}
                </span>
                    </div>
                  </v-col>
                  <v-col class="label" sm="3">
                    {{ $t('private') }} :
                  </v-col>

                  <v-col sm="3">
                    <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                      <v-switch
                          color="#00897E"
                          inset
                          hide-details
                          readonly
                          v-model="location.private"

                      />
                      <span>
                  {{ $t('yes') }}
                </span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('location-type') }} :
                  </v-col>
                  <v-col sm="3">
                    <template v-if="location.type">
                      {{ $t('enums.location-type.' +  location.type) }}
                    </template>
                    <template v-else>-</template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('location.owner-types') }} :
                  </v-col>
                  <v-col sm="3">
                    <template v-if="location.ownerType">
                      {{ $t('enums.location-owner-type.' +  location.ownerType) }}
                    </template>
                    <template v-else>-</template>
                  </v-col>
                  <v-col class="label" sm="3">
                    {{ $t('accessibility') }} :
                  </v-col>
                  <v-col sm="3" class="primary--text">
                    {{ location.accessibility ? location.accessibility : '-' }}
                  </v-col>

                </v-row>
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('location.timezone') }} :
                  </v-col>

                  <v-col sm="3">
                    {{location.timezone}}
                  </v-col>
                  <v-col class="label" sm="6">


                  </v-col>
                </v-row>
                <v-row>

                  <v-col class="label" sm="3">
                    {{ $t('location.additional-information') }} :
                  </v-col>
                  <v-col sm="3">
                    <v-list v-if="location.direction.length > 0"  density="compact">
                      <v-list-item v-for="(direction, index) in location.direction" :key="index"  class="pt-0 pb-0">
                        <v-list-item-content v-if="direction!==null">
                          <v-list-item-title>{{ direction.value }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <span v-else>-</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('facilities') }} :
                  </v-col>
                  <v-col sm="3">
                    <v-list v-if="location.facilities.length > 0">
                      <v-list-item v-for="(facility, index) in location.facilities" :key="index">
                        <v-list-item-content v-if="facility!==null">
                          <v-list-item-title>
                            {{ $t('enums.location-facility.' +  facility) }}

                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <span v-else>-</span>
                  </v-col>
                </v-row>
              </div>
              <div class="col-sm">
                <v-row>
                  <div class="map">
                    <template>
                      <Map ref="map" :items="markers" :isLocation="true"></Map>
                    </template>
                  </div>
                </v-row>
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('longitude') }} :
                  </v-col>
                  <v-col sm="3">
                    {{ location.coordinates.longitude}}
                  </v-col>
                  <v-col class="label" sm="3">
                    {{ $t('latitude') }} :
                  </v-col>
                  <v-col sm="3">
                    {{ location.coordinates.latitude}}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="3">
                    {{ $t('location.charging-when-closed') }} :
                  </v-col>
                  <v-col sm="3">
                    <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                      <v-switch
                          color="#00897E"
                          inset
                          hide-details
                          readonly
                          v-model="location.chargingWhenClosed"

                      />
                      <span>
                  {{ $t('yes') }}
                </span>
                    </div>
                  </v-col>
                  <v-col class="label" sm="3">
                    {{ $t('twenty-four-seven') }} :
                  </v-col>

                  <v-col sm="3">
                    <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                      <v-switch
                          color="#00897E"
                          inset
                          hide-details
                          readonly
                          v-model="location.twentyFourSeven"

                      />
                      <span>
                  {{ $t('yes') }}
                </span>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="location.openingTimes.length > 0">
                  <v-spacer></v-spacer>
                  <v-col sm="6">
                      <v-list class="w-100">
                        <v-subheader>  {{ $t('opening-hours') }}</v-subheader>
                        <v-list-item-group
                            color="primary"
                        >
                          <v-list-item
                              v-for="(item, i) in location.openingTimes"
                              :key="i"
                          >
                            <v-list-item-icon>
                              <v-icon small>mdi-clock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t('businessHour.days.'+item.weekday.toLowerCase()) }}
                              </v-list-item-title>
                              {{ item.periodBegin }} - {{ item.periodEnd }}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>

        <!--  <v-row>
            <v-col class="label" sm="3">
              {{ $t('name') }} :
            </v-col>
            <v-col sm="9">
              {{location.name}}

              <v-btn small class="ml-3" :to="{name: 'location.edit'}">
                {{ $t('charging-point.edit-location') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('organization.organization') }} :
            </v-col>
            <v-col sm="9">
              <router-link :to="{name: 'organizations.detail', params: {id: location.organization.id }}" v-if="location.organization">
                {{ location.organization.name}}
              </router-link>
              <template v-else>-</template>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('address') }} :
            </v-col>
            <v-col sm="9" class="address">
              <template v-if="location.address && location.address.street">
                {{ location.address.number }} {{ location.address.street }}
              </template>
              <template v-else>-</template>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('additional-information') }} :
            </v-col>
            <v-col sm="9">
              <template v-if="location.address && location.address.additionalInformation">
                {{ location.address.additionalInformation }}
              </template>
              <template v-else>-</template>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('zipcode') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="location.address && location.address.zipcode">
                {{ location.address.zipcode }}
              </template>
              <template v-else>-</template>
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('city') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="location.address && location.address.city">
                {{ location.address.city }}
              </template>
              <template v-else>-</template>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('state') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="location.address && location.address.state">
                {{ location.address.state }}
              </template>
              <template v-else>-</template>
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('country') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="location.address && location.address.country">
                {{ location.address.country }}
              </template>
              <template v-else>-</template>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('publish') }} :
            </v-col>

            <v-col sm="3">
              <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                <v-switch
                    color="#00897E"
                    inset
                    hide-details
                    readonly
                    v-model="location.publish"

                />
                <span>
                  {{ $t('yes') }}
                </span>
              </div>
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('private') }} :
            </v-col>

            <v-col sm="3">
              <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                <v-switch
                    color="#00897E"
                    inset
                    hide-details
                    readonly
                    v-model="location.private"

                />
                <span>
                  {{ $t('yes') }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('longitude') }} :
            </v-col>
            <v-col sm="3">
              {{ location.coordinates.longitude}}
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('latitude') }} :
            </v-col>
            <v-col sm="3">
              {{ location.coordinates.latitude}}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('location-type') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="location.type">
                {{ $t('enums.location-type.' +  location.type) }}
              </template>
              <template v-else>-</template>
            </v-col>

            <v-col class="label" sm="3">
              {{ $t('location.owner-types') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="location.ownerType">
                {{ $t('enums.location-owner-type.' +  location.ownerType) }}
              </template>
              <template v-else>-</template>
            </v-col>
          </v-row>
          <v-row>

            <v-col class="label" sm="3">
              {{ $t('accessibility') }} :
            </v-col>
            <v-col sm="3" class="primary--text">
              {{ location.accessibility ? location.accessibility : '-' }}
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('facilities') }} :
            </v-col>
            <v-col sm="3">
              <v-list v-if="location.facilities.length > 0">
                <v-list-item v-for="(facility, index) in location.facilities" :key="index">
                  <v-list-item-content v-if="facility!==null">
                    <v-list-item-title>
                      {{ $t('enums.location-facility.' +  facility) }}

                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <span v-else>-</span>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="label" sm="3">
              {{ $t('location.charging-when-closed') }} :
            </v-col>
            <v-col sm="3">
              <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                <v-switch
                    color="#00897E"
                    inset
                    hide-details
                    readonly
                    v-model="location.chargingWhenClosed"

                />
                <span>
                  {{ $t('yes') }}
                </span>
              </div>
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('twenty-four-seven') }} :
            </v-col>

            <v-col sm="3">
              <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                <v-switch
                    color="#00897E"
                    inset
                    hide-details
                    readonly
                    v-model="location.twentyFourSeven"

                />
                <span>
                  {{ $t('yes') }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('location.timezone') }} :
            </v-col>

            <v-col sm="3">
              {{location.timezone}}
            </v-col>
            <v-col class="label" sm="6">


            </v-col>
          </v-row>
          <v-row>

            <v-col class="label" sm="3">
              {{ $t('location.additional-information') }} :
            </v-col>
            <v-col sm="3">
              <v-list v-if="location.direction.length > 0"  density="compact">
                <v-list-item v-for="(direction, index) in location.direction" :key="index"  class="pt-0 pb-0">
                  <v-list-item-content v-if="direction!==null">
                    <v-list-item-title>{{ direction.value }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <span v-else>-</span>
            </v-col>
          </v-row>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tools from "@includes/tools";
import Map from '@blocks/Map'

import {LatLng} from "leaflet";

export default {
  components: {
    Map,
  },
  data() {
    return {
      markers: [],
    }
  },
  mounted() {
    this.loadMarkers()
  },
  watch: {

  },

  computed: {
    Tools() {
      return Tools
    },
    location() {
      return this.$parent.location
    },
  },
  methods: {

    loadMarkers() {
      this.markers=[];
        this.markers.push({
          id: this.location.id,
          position: {
            latitude: this.location.coordinates?.latitude,
            longitude: this.location.coordinates?.longitude,
          },
          status:[{
            0:'AVAILABLE'
          }]
        })
    },
  },
}
</script>

<style lang="scss">
  .location-info {
    .location-fiche {
      display: flex;
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        .v-btn {
          margin-bottom: 30px;
        }
      }
      .right {
        flex: 1;
      }
      .v-text-field {
        box-shadow: 0 10px 30px #00000029;
        .v-text-field__slot {
          height: 60px;
        }
      }

      .v-btn {
        margin: 20px 0;
        &.v-btn--disabled {
          &.v-btn--has-bg {
            background-color: #5F6569 !important;
          }
        }
      }
    }
    .map {
      float:right;
      display: flex;
      margin:0 100px 50px 100px;
      flex-direction: column;
      width: 100%;
      height: 500px;
      flex: unset;

      .th-map {
        border-left: 1px solid #ccc;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: #5F6569;
        height: 30px;
        margin-bottom: 20px;

      }

      .map-block {
        border: 3px solid #E53C22;
        border-radius: 0 10px 10px 0;
        overflow: hidden;
        min-height: 300px;
        position: sticky;
        top: 30px;
      }
    }
  }
  .location-list-item {
    margin-top: 4px; /* Ajuster l'espacement vertical */
    margin-bottom: 4px; /* Ajuster l'espacement vertical */
  }

</style>
