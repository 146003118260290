<template>


  <div class="location-station">
    <headline>
      {{ $t('charging-point.charging-points') }}
    </headline>

      <charging-point-list
          :locationId="location.id"
          ref="chargingPointList"
      />

  </div>
</template>

<script>

import ChargingPointList from "@/components/elements/ChargingPointList.vue";


export default {
  components: {
    ChargingPointList,
  },
  data() {
    return {
      items: [],
    }
  },

  mounted() {
    this.total = this.$refs.chargingPointList.total;
  },

  computed:  {
    location() {
      return this.$parent.location
    },
  },

  watch: {

  },

  methods: {

  }
}
</script>

<style lang="scss">
.location-station {

}

</style>
