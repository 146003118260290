<template>
  <div class="location-detail pa-10 pt-0" v-if="location">

    <tab-navigation
        :title="$tc('location.location')"
        icon="mdi-map-marker"
        :breadcrumbs="[
          {text: $tc('location.location', 2), to: {name: 'locations'} },
          {text: location.organization.name, to: {name: 'organizations.detail', params: {id: location.organization.id}}, exact: true},
          {text: locationLabel},
      ]"
    >
      <v-tabs>
        <v-tab :to="{name: 'location.detail'}" exact>{{ $t('informations') }}</v-tab>
        <v-tab :to="{name: 'location.detail.charging-points'}">{{ $t('charging-point.charging-points') }}</v-tab>
        <v-tab :to="{name: 'location.detail.meter-devices'}">{{ $tc('meter-device.meter-device',2) }}</v-tab>
        <v-tab  :to="{name: 'location.detail.usages'}">{{ $t('charging-point.usage') }}</v-tab>
        <v-tab :to="{name: 'location.detail.statistics'}">{{ $tc('statistic',2) }}</v-tab>
      </v-tabs>
    </tab-navigation>


    <router-view></router-view>

  </div>
</template>

<script>


import LocationRepository from "@repository/LocationRepository";
import PrevRouteMixin from "@mixins/PrevRouteMixin.vue";
import TabNavigation from "@blocks/TabNavigation.vue";
import Tools from "@includes/tools";


export default {
  components: {TabNavigation},

  data() {
    return {
      location: null,
    }
  },

  mixins: [
    PrevRouteMixin
  ],

  mounted() {
   /* if(!this.currentUser.permissions.includes('CUSTOMER_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }*/
    this.loadLocation()
  },

  watch: {

  },

  computed: {

    locationLabel() {
      let tmp = []
      if(this.location?.name) {
        tmp.push(this.location?.name)
      }

      if(tmp.length === 0) {
        tmp.push(this.location?.evcoId)
      }

      return tmp.join(' ')
    }
  },

  methods: {
    loadLocation() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)
        LocationRepository
            .getByIdentifier(this.$route.params.id).then(location => {
              this.location = location
              this.showLoading(false)
              resolve(location)
            })
            .catch(err => {
              this.showLoading(false)
              this.notifyError(err)
              reject(err)
            })
        })
    },
  }
}
</script>

<style lang="scss">
.location-detail {
  .nav-page {
    display: flex;
    padding-left: 0 !important;
    list-style: none;
    li {
      & + li {
        margin-left: 20px;
      }
    }
  }

  .main {
    .left{
      width: 400px;
      flex: unset;
      .avatar {
        text-align: center;
        margin-top: 30px;
        .v-avatar {
          background-color: #75809333;
          border: 1px solid  #707070;
          font-size: 90px;
          font-weight: bold;
          color: white;
        }
        .statut {
          position: relative;
          border-radius: 35px;
          font-weight: bold;
          color:white;
          height: 50px;
          display: flex;
          font-size: 18px;
          align-items: center;
          justify-content: center;
          margin: -30px 50px 20px 50px;
          &.enable {
            background: #00897E;
          }
          &.disable {
            background: #890511;
          }
        }
      }



      .tariff {
        border: 1px solid #707070;
        color: #758093;
        box-shadow: 0px 0px 30px #00000029;
        border-radius: 15px;
        padding: 10px;
        .tariff-info {
          .tariff-title {
            text-transform: uppercase;
          }
          .row {
            margin: 0;
            padding: 0 20px;
            border-bottom: 1px solid #75809340;
            &:last-child {
              border-bottom: 0;
            }
            .v-select {
              margin: 10px 0;
              position: relative;
              margin-left: -30px;
            }
            .cb {
              color: black;
              text-align: center;
              font-weight: bold;
            }
          }
        }

        .v-btn {
          margin-top: 20px;
        }
      }
    }
    .right{

    }
  }
}
</style>
