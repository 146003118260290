<template>
  <div class="locations-search pa-10">

    <title-page icon="mdi-map-marker">
      {{ $t('search-existing-location') }}
    </title-page>
    <v-row class="my-5">
      <v-col class="text-right">
        <v-btn
            @click="$refs.locationModal.open()"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t('location.add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="9">
        <v-card>
          <v-card-title>
            {{ $t("search-existing-location") }}
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="search" class="form-filters">
              <v-row dense>
                <v-col>
                  <v-text-field
                      v-model="filters.address"
                      :loading="loading"
                      :label="$t('location.find-existing-location')"
                      :placeholder="$t('start-typing-to-search')"
                      prepend-icon="mdi-map-search-outline"
                      clearable
                  >
                  </v-text-field>
                </v-col>

              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                      :label="$t('name')"
                      v-model="filters.name"
                      :placeholder="$t('start-typing-to-search')"
                      clearable
                  />
                </v-col>
                <v-col>
                  <organization-select
                      v-model="filters.organizationId"
                      :placeholder="$t('organization.organization')"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-select
                      clearable
                      hide-details
                      :label="$t('location-type')"
                      type="select"
                      :items="types"
                      v-model="filters.type"
                  />
                </v-col>
                <v-col>
                  <v-select
                      clearable
                      hide-details
                      :label="$t('location.owner-types')"
                      type="select"
                      :items="ownerType"
                      v-model="filters.ownerType"
                  />
                </v-col>
                <v-col>
                  <v-select
                      clearable
                      hide-details
                      :label="$t('accessibility')"
                      type="select"
                      :items="accessibilities"
                      v-model="filters.accessibilities"
                  />
                </v-col>
                <v-col>
                  <v-select
                      clearable
                      hide-details
                      :label="$t('facilities')"
                      type="select"
                      :items="facilities"
                      v-model="filters.facilities"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-right">
                  <v-btn type="submit" color="primary" small>{{ $t('search') }}</v-btn>
                </v-col>
              </v-row>

            </v-form>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-row>
          <v-col class="d-flex">
            <div class="map">
              <template>
                <Map ref="map" :items="markers" :isLocation="true"></Map>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="cp-list"
            :headers="headers"
            :items="locations"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [
                  20, 50, 100
              ]
            }"
            :options.sync="options"
            :server-items-length="total"
            @click:row="row => $router.push({name: 'location.detail', params: {id: row.id}})"
        >
          <template #item.name="{item}">
            <span class="firstname">{{ Tools.capitaliserFirstLetter(item.name) }}</span>
          </template>

          <template #item.address="{value}">
            {{ value.street }} {{ value.number }}, {{ value.zipcode }} {{ value.city }} - {{ value.country }}
          </template>

          <template #item.accessibility="{value}">{{ $t('enums.location-accessibility.' + value) }}</template>

          <template #item.countByType="{value}">
            <template v-if="true">
              <div class="counters" v-if="value.total > 0">
                <div class="statues">
                  <template v-for="(item, index) in value">
                    <v-tooltip bottom :key="index" :disabled="!index">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="counter" v-if="index !== 'total'" :class="{[index.toLowerCase()]: true}">
                          <v-icon  key="index" v-if="index" left>ico-status-{{ index.toLowerCase() }}</v-icon> {{ item }}
                        </div>
                      </template>
                      {{ $t('enums.evse-status.' + index) }}
                    </v-tooltip>
                  </template>
                </div>
<!--                <div class="slash">-->
<!--                  <v-icon>mdi-slash-forward</v-icon>-->
<!--                </div>-->
<!--                <div  class="total">-->
<!--                  <div class="counter">-->
<!--                    {{ value.total }}-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <div v-else>
                -
              </div>
            </template>


            <template v-else>
              <div  class="d-flex justify-start" v-if="value.length !== 0">
                <span v-for="(item, index) in value" :key="index">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                          v-if="index!=='total'"
                          label
                          :color="statusColor[index]"
                          text-color="white"
                          v-bind="attrs"
                          v-on="on"
                      >
                        {{ item }}
                      </v-chip>
                      <v-chip
                          v-else
                          label
                          color="grey"
                          text-color="black"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                      >
                         <!-- <v-icon left class="deep-orange--text text--darken-4">mdi-information-outline</v-icon>-->
                      {{ item }}
                      </v-chip>
                    </template>
                    <span>{{ `Status: ${index}` }}</span>
                  </v-tooltip>
                </span>
              </div>
              <div v-else>
                -
              </div>
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <location-modal ref="locationModal" @location-created="onLocationCreated" create-only />

  </div>
</template>

<script>

import LocationModal from "@pages/ChargingPoint/Detail/modals/LocationModal";
import LocationRepository from "@repository/LocationRepository";
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import Tools from "@includes/tools";
import Map from '@blocks/Map'


export default {
  name: 'LocationSearch',
  components: {
    OrganizationSelect,
    LocationModal,
    Map
  },
  data() {
    return {
      createdLocation: null,
      loading: false,
      locations: [],
      total: 0,
      options: {
        itemsPerPage: 10,
      },
      statusColor: {
        BLOCKED: '#C131A3',
        AVAILABLE: '#00897B',
        CHARGING: '#E0B24A',
        OFFLINE: '#EB4929',
      },
      markers: [],
      filters: {
        search: null,
        address: '',
        organizationId: null,
        name: '',
      }
    }
  },

  mounted() {
    this.search()
  },

  watch: {


    options(val) {
      this.searchData()
    }
  },

  computed: {
    Tools() {
      return Tools
    },
    headers() {
      return [
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('accessibility'), value: 'accessibility'},
        {text: this.$t('organization.organization'), value: 'organization.name'},
        {text: this.$tc('location.location'), value: 'address'},
        {text: this.$t('location.stations-count'), value: 'countByType'},
      ]
    },
    types(){

      return [
        {text: this.$t('enums.location-type.ON_STREET'), value: 'ON_STREET'},
        {text: this.$t('enums.location-type.PARKING_GARAGE'), value: 'PARKING_GARAGE'},
        {text: this.$t('enums.location-type.UNDERGROUND_GARAGE'), value: 'UNDERGROUND_GARAGE'},
        {text: this.$t('enums.location-type.PARKING_LOT'), value: 'PARKING_LOT'},
        {text: this.$t('enums.location-type.OTHER'), value: 'OTHER'},
        {text: this.$t('enums.location-type.UNKNOWN'), value: 'UNKNOWN'}

      ]

    },
    ownerType(){
      return [
        {text: this.$t('enums.location-owner-type.HOME'), value: 'HOME'},
        {text: this.$t('enums.location-owner-type.STORE'), value: 'STORE'},
        {text: this.$t('enums.location-owner-type.EV_STATION'), value: 'EV_STATION'},
        {text: this.$t('enums.location-owner-type.PUBLIC_SITE'), value: 'PUBLIC_SITE'},
        {text: this.$t('enums.location-owner-type.PARKING'), value: 'PARKING'},
        {text: this.$t('enums.location-owner-type.OTHER'), value: 'OTHER'},
        {text: this.$t('enums.location-owner-type.UNKNOWN'), value: 'UNKNOWN'},
      ]
    },
    accessibilities(){
      return [
        {text: this.$t('enums.location-accessibility.FREE_ACCESS'), value: 'FREE_ACCESS'},
        {text: this.$t('enums.location-accessibility.RESTRICTED_ACCESS'), value: 'RESTRICTED_ACCESS'},
        {text: this.$t('enums.location-accessibility.PAID_ACCESS'), value: 'PAID_ACCESS'},
        {text: this.$t('enums.location-accessibility.TEST_STATION'), value: 'TEST_STATION'}
      ]
    },
    facilities() {
      return [
        { text: this.$t('enums.location-facility.HOTEL'), value: "HOTEL" },
        { text: this.$t('enums.location-facility.RESTAURANT'), value: "RESTAURANT" },
        { text: this.$t('enums.location-facility.CAFE'), value: "CAFE" },
        { text: this.$t('enums.location-facility.MALL'), value: "MALL" },
        { text: this.$t('enums.location-facility.SUPERMARKET'), value: "SUPERMARKET" },
        { text: this.$t('enums.location-facility.SPORT'), value: "SPORT" },
        { text: this.$t('enums.location-facility.RECREATION_AREA'), value: "RECREATION_AREA" },
        { text: this.$t('enums.location-facility.NATURE'), value: "NATURE" },
        { text: this.$t('enums.location-facility.MUSEUM'), value: "MUSEUM" },
        { text: this.$t('enums.location-facility.BUS_STOP'), value: "BUS_STOP" },
        { text: this.$t('enums.location-facility.TAXI_STAND'), value: "TAXI_STAND" },
        { text: this.$t('enums.location-facility.TRAIN_STATION'), value: "TRAIN_STATION" },
        { text: this.$t('enums.location-facility.AIRPORT'), value: "AIRPORT" },
        { text: this.$t('enums.location-facility.CARPOOL_PARKING'), value: "CARPOOL_PARKING" },
        { text: this.$t('enums.location-facility.FUEL_STATION'), value: "FUEL_STATION" },
        { text: this.$t('enums.location-facility.WIFI'), value: "WIFI" },
      ];
    }
  },

  methods: {
    search() {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.searchData()
        this.loadMarkers()
      }
    },
    loadMarkers(locations) {
      this.markers=[];
      for (let location of locations) {
        this.markers.push({
          id: location.id,
          position: {
            latitude: location.coordinates?.latitude,
            longitude: location.coordinates?.longitude,
          },
          status:[{
            0:'AVAILABLE'
          }]
        })
      }
    },
    searchData() {
      let options = this.options
      let search = this.filters.address;
      let name = this.filters.name;
      let organizationId = this.filters.organizationId;
      let ownerType = this.filters.ownerType;
      let type = this.filters.type;
      let accessibility = this.filters.accessibilities;
      let facilities = this.filters.facilities;
      let offset = options.itemsPerPage * options.page - options.itemsPerPage;
      let limit = options.itemsPerPage;
      let orderBy = options.sortBy.length > 0 ? options.sortBy[0] : 'createdAt'
      let orderDirection = options.sortDesc.length > 0 && options.sortDesc[0] ? 'desc' : 'asc'
      const data = {
        ownerType,
        type,
        accessibility,
        facilities,
        search,
        name,
        organizationId,
        offset,
        limit,
        orderBy,
        orderDirection,
        all: true,
      }
      this.loading = true
      LocationRepository.search(data).then(result => {
        this.total = result.total
        this.locations = result.items
        this.loadMarkers(this.locations)
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.loading = false
      })
    },

    onLocationCreated(location) {
      this.$router.push({name: 'location.detail', params: {id: location.id}})
    },

  }
}
</script>

<style lang="scss">
.locations-search {
  .v-card__title {
    padding-bottom: 7px;
  }
  .v-data-table {
    td {
      cursor: pointer;

      .click {
        text-decoration: none;
        color: #71A723;
      }

      .number {
        color: #E53C22;
        font-weight: bold;
      }

      .lastname, .firstname {
        color: #E53C22;
        font-weight: bold;
      }

      .company {
        font-weight: 100;
      }
    }
  }
  .counters {
    margin: 5px;
    display: flex;
    height: 50px;
    //border: 1px solid #ccc;
    //border-radius: 4px;
    & > div {
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }
    .counter {
      display: flex;
      flex-grow: 1;
      border-radius: 4px;
      font-size: 14px;
      margin: 3px;
      max-width: 40px;
      padding: 0 25px;
      justify-content: center;
      align-items: center;
      background: #424242;
      color: white;
      box-shadow: 0 0 15px 0 #ccc;
      .v-icon {
        color: white;
        font-size: 16px;
      }
      &.available {
        background: #00897E;
        box-shadow: 0 0 10px 0 #00897E90;
      }
      &.blocked {
        background: #b3028d;
        box-shadow: 0 0 10px 0 #b3028d90;
      }
      &.charging {
        background: #DEAC3C;
        box-shadow: 0 0 10px 0 #DEAC3C90;
      }
      &.offline {
        background: #E53C22;
        box-shadow: 0 0 10px 0 #E53C2290;
      }
      &.outoforder {
        background: #758093;
        box-shadow: 0 0 10px 0 #75809390;
      }
      &.unknown {
        background: #000000;
        box-shadow: 0 0 10px 0 #00000090;
      }
      &.planned {
        background: #1586e3;
        box-shadow: 0 0 10px 0 #1586e390;
      }
    }
    .slash {
      display: flex;
      flex-grow: 0;
    }
    .total {
      display: flex;
      flex-grow: 0;
      width: 40px;
      .counter {
        color: black;
        box-shadow: unset;
      }
    }
  }

  .map {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: unset;

    .th-map {
      border-left: 1px solid #ccc;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #5F6569;
      height: 30px;
      margin-bottom: 20px;

    }

    .map-block {
      border: 3px solid #E53C22;
      border-radius: 0 10px 10px 0;
      overflow: hidden;
      min-height: 300px;
      position: sticky;
      top: 30px;
    }
  }
}



</style>
