<template>


  <div class="location-station">
    <headline>
      {{ $tc('statistic',2) }}
    </headline>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,

      total: 0,
      items: [],
    }
  },

  mounted() {

  },

  computed:  {
    location() {
      return this.$parent.location
    },

  },

  watch: {

  },

  methods: {
  }
}
</script>

<style lang="scss">
.location-station {

}

</style>
