<template>


  <div class="location-station">
    <headline>
     {{ $tc('meter-device.meter-device',2)}}
    </headline>
    <v-row >
      <v-col class="text-right">
        <v-btn
            :to="{ name: 'meter-device.add', query: {locationId: location.id, organizationId: location.organization.id}}"
            small>
          <v-icon left>mdi-plus</v-icon>
          {{ $t('meter-device.add')}}
        </v-btn>
      </v-col>
    </v-row>
    <br>
    <meter-device-list
        :count.sync="meterDevicesCount"
        :loaded.sync="meterDevicesLoaded"
        :locationId="location.id"
    />
  </div>
</template>

<script>
import MeterDeviceList from "@/components/elements/MeterDeviceList.vue";

export default {
  components: {
    MeterDeviceList,
  },
  data() {
    return {
      loading: false,
      meterDevicesCount: 0,
      meterDevicesLoaded: false,

    }
  },

  mounted() {

  },

  computed:  {
    location() {
      return this.$parent.location
    },
  },

  watch: {

  },

  methods: {

  }
}
</script>

<style lang="scss">
.location-station {

}

</style>
