<template>

  <div class="location-edit" v-if="localLocation">
    <v-form  ref="form" @submit.prevent="save">

      <div class="right">
        <headline bg>
          {{ $t("location.additional-information") }}
        </headline>

        <div>

          <v-row>
            <v-col cols="12">
             <form-field
                :label="$t('name')"
                v-model="localLocation.name"
                col-field="9"
            />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <form-field
                  :label="$t('organization.organization')"
                  v-model="localLocation.organizationId"
                  type="tree-select-organization"
                  col-field="9"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12">
              <v-card
                  class="mx-auto pa-3"
                  outlined
                  cols="10"
              >
                <v-card-title style="padding-left:25%;color:#e53c22">
                  <v-icon
                      left
                      color="#e53c22"
                  >
                    mdi-pencil
                  </v-icon>
                  {{ $t('location.edit') }}
                </v-card-title>
                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('number')"
                        v-model="localLocation.address.number"
                        :rules="[commonRules.required]"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('street')"
                        v-model="localLocation.address.street"
                        :rules="[commonRules.required]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <form-field
                        :label="$t('additional-information')"
                        v-model="localLocation.address.additionalInformation"
                        col-field="9"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('zipcode')"
                        v-model="localLocation.address.zipcode"
                        :rules="[commonRules.required]"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('city')"
                        v-model="localLocation.address.city"
                        :rules="[commonRules.required]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('state')"
                        v-model="localLocation.address.state"
                        :rules="[commonRules.required]"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('country')"
                        type="available-select"
                        input-type="country"
                        v-model="localLocation.address.country"
                    />
                  </v-col>
                </v-row>

                <v-col cols="12">
                  <v-card-title style="padding-left:25%;color:#e53c22">
                    <v-icon
                        left
                        color="#e53c22"
                    >
                      mdi-map-search
                    </v-icon>
                    {{ $t('location.select-location') }}
                  </v-card-title>
                  <div class="map-edit" v-if="localLocation && localLocation.coordinates">
                    <Map
                        ref="map-container"
                        :zoom="16"
                        @move="centerMarker"
                        @zoomlevelschange="centerMarker"
                        @drag="centerMarker"
                        :on-map-ready="onMapReady"
                    >
                      <l-marker
                          v-if="marker"
                          :icon="selectedIcon"
                          :lat-lng="marker"
                      />
                    </Map>
                  </div>
                  <br>
                  <v-row>
                    <v-col cols="12" md="6">
                      <form-field
                          :label="$t('longitude')"
                          v-model="localLocation.coordinates.longitude"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <form-field
                          :label="$t('latitude')"
                          v-model="localLocation.coordinates.latitude"
                      />

                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  :label="$t('publish')"
                  type="switch"
                  v-model="localLocation.publish"
              />
            </v-col>


            <v-col cols="12" md="6">
              <form-field
                  :label="$t('private')"
                  type="switch"
                  v-model="localLocation.private"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  :label="$t('location-type')"
                  type="select"
                  :items="types"
                  v-model="localLocation.type"
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-field
                  :label="$t('location.owner-types')"
                  type="select"
                  :items="ownerType"
                  v-model="localLocation.ownerType"
              />

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
            <form-field
                :label="$t('accessibility')"
                type="select"
                :items="accessibilities"
                v-model="localLocation.accessibility"
            />
          </v-col>
            <v-col cols="12" md="6">
              <form-field
                  :label="$t('facilities')"
                  v-model="localLocation.facilities"
                  type="select"
                  :items="facilities"
                  multiple
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  :label="$t('location.charging-when-closed')"
                  type="switch"
                  v-model="localLocation.chargingWhenClosed"
              />
            </v-col>


            <v-col cols="12" md="6">
              <form-field
                  :label="$t('twenty-four-seven')"
                  type="switch"
                  v-model="localLocation.twentyFourSeven"
              />
              <v-row v-if="!localLocation.twentyFourSeven">
                <v-col>
                  <div class="label">
                    {{ $t('location.openingTimes') }}
                  </div>
                  <opening-times v-model="localLocation.openingTimes"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  :label="$t('timezone')"
                  v-model="localLocation.timezone"
                  type="available-select"
                  input-type="timezone"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
            </v-col>
            <v-col cols="12" md="6">
              <localized-string
                  :label="$t('location.additional-information')"
                  v-model="localLocation.direction"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-btn class="mr-5" @click="cancel">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary" type="submit">
                {{ $t('save') }}
              </v-btn>
            </v-col>

          </v-row>

        </div>


      </div>

    </v-form>
  </div>
</template>

<script>
import LocationRepository from "@repository/LocationRepository";
import RulesMixin from "@mixins/RulesMixin";
import LocalizedString from "@blocks/LocalizedString.vue";
import AvailableSelect from "@blocks/Select/AvailableSelect.vue";
import OpeningTimes from "@blocks/OpeningTimes.vue";
import Map from "@blocks/Map.vue";
import {Icon, LatLng} from "leaflet";
import {LMarker} from "vue2-leaflet";
import iconSelected from "@images/marker-selected.svg";
import iconShadow from "@images/marker-shadow.png";

export default {
  components: {
    LocalizedString,
    OpeningTimes,
    Map,
    LMarker,
  //  AvailableSelect
  },
  data() {
    return {
      localLocation: {
        "company": null,
        "customId": null,
        "email": null,
        "externalId": null,
        "name": null,
        "organizationId": null,
      },
      selectedIcon: new Icon({
        iconUrl: iconSelected,
        iconSize: [50, 72],
        iconAnchor: [25, 72],
        shadowUrl: iconShadow,
        shadowSize: [41, 41],
        shadowAnchor: [13, 41]
      }),
      marker: null,
    }
  },

  mixins: [RulesMixin],

  watch: {
    location(val) {
      this.localLocation = this.cloneObj(val)
    },

    localLocation: {
      handler(val) {
        this.setUnsavedChanges()
      },
      deep: true

    },
  },

  mounted() {
    if (!this.currentUser.permissions.includes('CUSTOMER_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

    this.localLocation = this.cloneObj(this.location)
    this.localLocation.organizationId = this.localLocation.organization?.id

    this.$nextTick(() => {
      this.cleanUnsavedChanges()
    })

  },

  computed: {

    location() {
      return this.$parent.location
    },
    types(){
      return [
        {text: this.$t('enums.location-type.ON_STREET'), value: 'ON_STREET'},
        {text: this.$t('enums.location-type.PARKING_GARAGE'), value: 'PARKING_GARAGE'},
        {text: this.$t('enums.location-type.UNDERGROUND_GARAGE'), value: 'UNDERGROUND_GARAGE'},
        {text: this.$t('enums.location-type.PARKING_LOT'), value: 'PARKING_LOT'},
        {text: this.$t('enums.location-type.OTHER'), value: 'OTHER'},
        {text: this.$t('enums.location-type.UNKNOWN'), value: 'UNKNOWN'}

      ]

    },
    ownerType(){
      return [
        {text: this.$t('enums.location-owner-type.HOME'), value: 'HOME'},
        {text: this.$t('enums.location-owner-type.STORE'), value: 'STORE'},
        {text: this.$t('enums.location-owner-type.EV_STATION'), value: 'EV_STATION'},
        {text: this.$t('enums.location-owner-type.PUBLIC_SITE'), value: 'PUBLIC_SITE'},
        {text: this.$t('enums.location-owner-type.PARKING'), value: 'PARKING'},
        {text: this.$t('enums.location-owner-type.OTHER'), value: 'OTHER'},
        {text: this.$t('enums.location-owner-type.UNKNOWN'), value: 'UNKNOWN'},
      ]
    },
    accessibilities(){
      return [
        {text: this.$t('enums.location-accessibility.FREE_ACCESS'), value: 'FREE_ACCESS'},
        {text: this.$t('enums.location-accessibility.RESTRICTED_ACCESS'), value: 'RESTRICTED_ACCESS'},
        {text: this.$t('enums.location-accessibility.PAID_ACCESS'), value: 'PAID_ACCESS'},
        {text: this.$t('enums.location-accessibility.TEST_STATION'), value: 'TEST_STATION'}
      ]
    },
    facilities() {
      return [
        { text: this.$t('enums.location-facility.HOTEL'), value: "HOTEL" },
        { text: this.$t('enums.location-facility.RESTAURANT'), value: "RESTAURANT" },
        { text: this.$t('enums.location-facility.CAFE'), value: "CAFE" },
        { text: this.$t('enums.location-facility.MALL'), value: "MALL" },
        { text: this.$t('enums.location-facility.SUPERMARKET'), value: "SUPERMARKET" },
        { text: this.$t('enums.location-facility.SPORT'), value: "SPORT" },
        { text: this.$t('enums.location-facility.RECREATION_AREA'), value: "RECREATION_AREA" },
        { text: this.$t('enums.location-facility.NATURE'), value: "NATURE" },
        { text: this.$t('enums.location-facility.MUSEUM'), value: "MUSEUM" },
        { text: this.$t('enums.location-facility.BUS_STOP'), value: "BUS_STOP" },
        { text: this.$t('enums.location-facility.TAXI_STAND'), value: "TAXI_STAND" },
        { text: this.$t('enums.location-facility.TRAIN_STATION'), value: "TRAIN_STATION" },
        { text: this.$t('enums.location-facility.AIRPORT'), value: "AIRPORT" },
        { text: this.$t('enums.location-facility.CARPOOL_PARKING'), value: "CARPOOL_PARKING" },
        { text: this.$t('enums.location-facility.FUEL_STATION'), value: "FUEL_STATION" },
        { text: this.$t('enums.location-facility.WIFI'), value: "WIFI" },
      ];
    }
  },

  methods: {

    centerMarker() {
      let map = this.$refs['map-container'].$refs.map.mapObject;
      let center = map.getCenter()
      this.marker = center
      this.localLocation.coordinates.latitude = center.lat
      this.localLocation.coordinates.longitude = center.lng
    },

    onMapReady(map) {
      let center = new LatLng(this.localLocation.coordinates.latitude, this.localLocation.coordinates.longitude)
      map.panTo(center)
      this.marker = center
    },

    save() {

      if (this.$refs.form.validate()) {
        this.showLoading(true)
        if (!this.localLocation.enabled) {
          this.localLocation.enabled = false
        }
        LocationRepository.update(this.$route.params.id, this.localLocation).then(response => {
          this.$dialog.notify.success(this.$t('updated-successfully'))
          this.cleanUnsavedChanges()
          this.$router.replace({name: 'location.detail'})
          this.$parent.loadLocation().then(() => {
            this.showLoading(false)
          })
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
          this.showLoading(false)
        })

      }

    },

    cancel() {
       this.$router.replace({name: 'location.detail'})
     },

  },


}
</script>

<style lang="scss">
.location-edit {
  .customer-fiche {
    display: flex;

    .right {
      flex: 1;
    }

  }
  .map-block {
    height: 200px;
    width:90%;
    margin:auto;
  }
}

</style>
